@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap");

@font-face {
  font-family: "Insanibc";
  src: url("./fonts/Insanibc.eot");
  src: local("☺"), url("./fonts/Insanibc.woff") format("woff"),
    url("./fonts/Insanibc.ttf") format("truetype"),
    url("./fonts/Insanibc.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Insanibc";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 36px;
}

p,
p span {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: #4b4b4b;
  margin: 30px 0;
  font-size: 24px;
}

.bold {
  font-weight: 700;
}

.purple {
  color: #4f00ff;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7b7b7b;
  background-repeat: repeat-y;
  background-size: 100%;
}

// .logo {
//   width: 200px;
//   margin-bottom: 20px;
//   margin-top: -40px;
// }

.logo {
  width: 175px;
  position: absolute;
  top: 30px;
  left: 40px;
}

.btn-wallet {
  position: absolute;
  top: 30px;
  right: 40px;
}

.btn-primary {
  background-image: linear-gradient(
    180deg,
    #913dff 0%,
    #7733f4 100%
  ) !important;
  box-shadow: 0 10px 14px 0 rgba(34, 33, 81, 0.25), 0 1px 0 1px #6327d3;
  border-radius: 16px;
  border: none;
  font-size: 28px;
  padding: 16px 32px;
  transition: all 0.2s;

  &:hover,
  &:focus {
    -webkit-transform: translate(0, 1px);
    -ms-transform: translate(0, 1px);
    transform: translateY(1px);
    box-shadow: 0 10px 14px 0 rgba(34, 33, 81, 0.02), 0 0px 0 1px #6327d3 !important;
    background-image: linear-gradient(180deg, #8b3bf5 0%, #7231eb 100%);
  }

  &.jumbo {
    // display: block;
    margin: 30px 0 15px;
    font-size: 42px;
    padding: 18px 38px;
    // transform: scale(1.3, 1.3);
    box-shadow: 0 14px 18px 0 rgba(34, 33, 81, 0.35), 0 1px 0 1px #6327d3;
  }
}

.btn-outline-primary {
  color: #7733f4;
  border-color: #7733f4 !important;
  box-shadow: none !important;

  &:hover,
  &:active {
    color: #fff;
    background-color: #7733f4;
  }

  &.disabled,
  &:disabled {
    color: #7733f4;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-text {
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
  color: #221454;

  &.bold {
    font-family: "Insanibc";
  }

  &:hover,
  &:focus {
    color: darken(#221454, 30%) !important;
  }
}

input.form-control {
  box-shadow: none !important;

  &:focus {
    border-color: #ced4da;
    border-right-color: #7733f4;
  }
}

.card,
.modal-content {
  box-shadow: 0 5px 14px 0 rgba(34, 33, 81, 0.12), 0 1px 0 1px #f9f9fb;
  border-radius: 16px;
  border: none;
  color: #221454;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 15px;
    text-align: center;
  }
}

.card {
  margin: 0 15px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-footer {
  justify-content: center;
}

.img-egg {
  margin-top: 30px;
  width: 75%;

  &.pending,
  &.error {
    margin: 5px;
    width: 40%;
  }
}
